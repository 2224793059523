export const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'is required!',
    },
    email: {
      message: 'is invalid!',
    },
  },
};
