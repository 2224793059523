import React from 'react';
import Layout from '../components/Layout';
import ContactUs from '../components/contact-us/ContactUs';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import DiscussForm from '../components/discuss-form/DiscussForm';
import CallToAction from '../components/call-to-action/CallToAction';

export default function LancerProjet({ data }) {
  const { markdownRemark: content } = data;
  const { contactus, project } = content.frontmatter;

  return (
    <Layout>
      <ContactUs data={contactus} />
      <DiscussForm />
      <CallToAction project={project} />
    </Layout>
  );
}

LancerProjet.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export const lancerProjetQuery = graphql`
  query LancerProjet {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        projectForm {
          title_fr
          title_en
          lastname_en
          lastname_fr
          firstname_en
          firstname_fr
          email_en
          email_fr
          phone_en
          phone_fr
          entreprise_en
          entreprise_fr
          expression_en
          expression_fr
          submitButton_en
          submitButton_fr
        }
        contactus {
          title_en
          title_fr
          desc_en
          desc_fr
          adresse_en
          adresse_fr
          email
          telephone
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        project {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          button_en
          button_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
