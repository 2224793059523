import React from 'react';
import './style.scss';
import { Select, Snackbar } from '@material-ui/core';
import { COUNTRIES } from './countries';
import { encode } from '../Newsletter';
import { Alert } from '@material-ui/lab';
import { constraints } from './constraints';
import validate from 'validate.js';

class DiscussForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        email: '',
        message: '',
        company: '',
        country: 'MA',
      },
      open: false,
      errors: {},
      isValidated: false,
    };
  }

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleSubmit = e => {
    let errors = validate(this.state.form, constraints);
    this.setState({ errors: errors });
    if (errors && Object.keys(errors).length != 0) {
      this.setState({ isValidated: false });
    } else {
      this.setState({ isValidated: true });
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'Discuss Form', ...this.state.form }),
      })
        .then(() =>
          this.setState({
            form: {
              name: '',
              email: '',
              message: '',
              company: '',
              country: 'MA',
            },
            open: true,
          }),
        )
        .catch(error => alert(error));
    }
    e.preventDefault();
  };

  render() {
    const { name, email, message, company, country } = this.state.form;
    const { open } = this.state;
    return (
      <div className="discuss-form" id="discuss">
        <div className="discuss-form__title">Let's discuss your project</div>
        <form
          name="Discuss Form"
          className="discuss-form__form"
          method="POST"
          className="apply-form__form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="discuss-form__form__element">
            <div className="discuss-form__form__element__title">Your name*</div>
            <input
              className="discuss-form__form__element__input"
              placeholder="Your name"
              name="name"
              value={name}
              onChange={this.handleChange}
              required
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.name && (
                <span className="error-utility">
                  {this.state.errors.name[0]}
                </span>
              )}
          </div>
          <div className="discuss-form__form__element">
            <div className="discuss-form__form__element__title">
              Contact email*
            </div>
            <input
              className="discuss-form__form__element__input"
              placeholder="you@example.com"
              name="email"
              value={email}
              onChange={this.handleChange}
              required
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.email && (
                <span className="error-utility">
                  {this.state.errors.email[0]}
                </span>
              )}
          </div>
          <div className="discuss-form__form__element">
            <div className="discuss-form__form__element__title">
              Company name*
            </div>
            <input
              className="discuss-form__form__element__input"
              placeholder="Company name"
              name="company"
              value={company}
              onChange={this.handleChange}
              required
            />
            {!this.state.isValidated &&
              this.state.errors &&
              this.state.errors.company && (
                <span className="error-utility">
                  {this.state.errors.company[0]}
                </span>
              )}
          </div>
          <div className="discuss-form__form__element">
            <div className="discuss-form__form__element__title">Country*</div>
            <Select
              disableUnderline
              onChange={this.handleChange}
              label="Country"
              defaultValue={country}
              classes={{ root: 'select-country' }}
              name="country"
              required
            >
              {COUNTRIES.map((country, index) => (
                <option value={country.code} key={index}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="discuss-form__form__element-2">
            <div className="discuss-form__form__element-2__title">
              Your message*
            </div>
            <textarea
              className="discuss-form__form__element-2__textarea"
              placeholder="Type your message…."
              name="message"
              value={message}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="discuss-form__form__remark">
            By submitting this form you agree to our terms and conditions and
            our Privacy Policy which explains how we may collect, use and
            disclose your personal information including to third parties.
          </div>
          <button
            className="discuss-form__form__button"
            onClick={this.handleSubmit}
          >
            Contact us
          </button>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="success">
              Thank you!
            </Alert>
          </Snackbar>
        </form>
      </div>
    );
  }
}

export default DiscussForm;
