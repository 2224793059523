import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import './newsletter.scss';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import validate from 'validate.js';
import { constraints } from './constraints';

export function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
      open: false,
      email: '',
      errors: {},
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    let errors = validate({ email: this.state.email }, constraints);
    this.setState({ errors: errors });
    if (errors && Object.keys(errors).length != 0) {
      this.setState({ isValidated: false });
    } else {
      this.setState({ isValidated: true });
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'Newsletter',
          ...this.state,
        }),
      })
        .then(() => this.setState({ open: true, email: '' }))
        .catch(error => alert(error));
    }
  };

  render() {
    return (
      <>
        <form
          className="newsLetter"
          name="Newsletter"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="POST"
        >
          <div className="newsLetter__left">
            <input
              className="newsLetter__input"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Enter your e-mail address"
              required
            />
          </div>
          <div className="newsLetter__right">
            <button className="cta-btn" name="send" onClick={this.handleSubmit}>
              Subscribe
              <ArrowForward fontSize="small" className="materiel-icon" />
            </button>
          </div>

          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={this.handleClose}
              severity="success"
            >
              {this.props.newsletterNotifSuccess}
            </Alert>
          </Snackbar>
        </form>
        {!this.state.isValidated && this.state.errors.email && (
          <div className="newsLetter__input--error">
            {this.state.errors.email[0]}
          </div>
        )}
      </>
    );
  }
}
export default Newsletter;
