import React from 'react';
import './style.scss';
import { useContext } from 'react';
import { LangContext } from '../Layout';
import Indice from '../../img/map-indice.inline.svg';
import Img from 'gatsby-image';
import MediaQuery from 'react-responsive';
import { MediaQuerySSR } from 'react-responsive-ssr';

const ContactUs = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="contact-us">
      <div className="contact-us__left">
        <div className="contact-us__left__title">
          {data['title_' + language]}
        </div>
        <div className="contact-us__left__desc">{data['desc_' + language]}</div>
        <div className="contact-us__left__credential">
          <img
            src={'/img/mail-blue.svg'}
            alt="Email"
            className="contact-us__left__credential__icon"
          />
          <div className="contact-us__left__credential__text">
            {data['email']}
          </div>
        </div>
        <div className="contact-us__left__credential">
          <img
            src={'/img/phone-blue.svg'}
            alt="Email"
            className="contact-us__left__credential__icon"
          />
          <div className="contact-us__left__credential__text">
            {data['telephone']}
          </div>
        </div>
        <div className="contact-us__left__credential">
          <img
            src={'/img/Address-blue.svg'}
            alt="Email"
            className="contact-us__left__credential__icon"
          />
          <div className="contact-us__left__credential__text">
            {data['adresse_' + language]}
          </div>
        </div>
      </div>
      <div className="contact-us__right">
        <div className="contact-us__right__desktop">
          <Img
            fluid={data.image.childImageSharp.fluid}
            className="contact-us__right__image"
            alt="Theodo"
            style={{ position: 'absolute' }}
          />
        </div>
        <div className="contact-us__right__mobile">
          <Img
            fluid={data.image.childImageSharp.fluid}
            className="contact-us__right__image"
            alt="Theodo"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
